import './App.css';
import * as React from 'react';
import { Button } from '@mui/base/Button';
import { Input } from '@mui/base/Input';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="App">
      <header className="App-form">
      <Stack spacing={2} direction="row">
      证明号 
      <Input aria-label="No" placeholder="" />
      </Stack >
      <Stack spacing={2} direction="row">
      日期
      <DatePicker aria-label="dtp's" />
      </Stack>
      <Stack spacing={2} direction="row">
      证件号码
      <Input arial-label="id" placeholder="" />
      </Stack>
      <Stack spacing={2} direction="row">
      折算总金额
      <Input arial-label="amount" placeholder="" />
      </Stack>
      <Stack spacing={2} direction="row">
      验证码
      <Input arial-label="code" placeholder="" />
      </Stack>
      <Button className='btn'>验证</Button>
      </header>
    </div>
  </LocalizationProvider>
  );
}

export default App;
